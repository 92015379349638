body {
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: inherit;
  font-weight: 100;
  line-height: 1.1;
  color: inherit;
}

h1, h2, h3, p {
  margin-bottom: 24px;
}

h1 {
  margin-top: 15px;
}

h2, h3 {
  margin-top: 40px;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.ms-Icon-ul {
  margin-left: 24px;
}

.ms-Icon-ul li {
  position: relative;
}

.ms-Icon-ul li i.ms-Icon {
  position: absolute;
  left: -24px;
}

.alert {
  padding: 8px 8px;
  border: 1px solid transparent;
  border-radius: 0;
}

.alert-warning {
  color: #000;
  background-color: #fff4ce;
  border-color: #fff4ce;
}
