.display-package .package-icon {
  margin-top: 15px;
}

.display-package .package-title {
  word-break: break-word;
  overflow-wrap: break-word;
}

.display-package h1 small {
  margin-left: 10px;
  font-size: 45%;
}

.package-details-info {
  margin-top: -25px;
}

.package-details-info .ms-Icon-ul li {
  margin-bottom: 15px;
}
